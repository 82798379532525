export const QuestionType = [
  { id: 'radio', name: '라디오 버튼' },
  { id: 'range', name: '범위 입력' },
  { id: 'checkbox', name: '체크박스' },
  { id: 'selectbox', name: '셀렉트박스' },
  { id: 'text', name: '텍스트 입력' },
  { id: 'airport', name: '공항 입력' },
  { id: 'korea-district', name: '지역 입력' },
];

export const AnswerStyle = [
  { id: '', name: '기본' },
  { id: '2column', name: '2열' },
];
