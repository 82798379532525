import * as React from 'react';
import {
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Edit,
  EditProps,
  SelectField,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  RadioButtonGroupInput,
  required,
  BooleanInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { JsonInput } from 'react-admin-json-view';

const validateName = required();

export const SurveyResponseEdit = (props: EditProps) => (
  <Edit {...props} queryOptions={{}}>
    <SimpleForm>
      <NumberInput source="id" disabled />
      <TextInput source="type" defaultValue={null} />
      <JsonInput source="result" validate={[required()]} jsonString={false} />
      <TextInput label="설명" source="description" validate={validateName} />
      <DateInput source="create_datetime" disabled />
    </SimpleForm>
  </Edit>
);
