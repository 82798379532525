// in src/users.js
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  NumberField,
  DateField,
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
  EditProps,
  Pagination,
  PaginationProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  useRecordContext,
  Link,
  ListActionsProps,
  CreateButton,
  TopToolbar,
  ReferenceField,
} from 'react-admin';
import { ChartData } from '../../type';
import { SyncButton } from './SyncButton';

const itemFilters = [
  <ReferenceInput label="Brand" source="brand" reference="brand" allowEmpty alwaysOn>
    <AutocompleteInput optionText="name" debounce={600} />
  </ReferenceInput>,
  <TextInput label="년도" source="year" alwaysOn />,
  <TextInput label="월" source="month" alwaysOn />,
];

const TagPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

type Statistics = {
  id: number;
  name: string;
  categories: any;
  data: string;
  brand_id: any;
};
const stopPropagation = (e: any) => e.stopPropagation();
const BrandField = ({ source }: any) => {
  const record = useRecordContext<Statistics>();
  if (!record?.brand_id) return null;
  const statistic = record;
  return (
    <span>
      <Link to={`/brand/${statistic.brand_id}`} onClick={stopPropagation}>
        {statistic.brand_id}
      </Link>
    </span>
  );
};

const ChartDataField = ({ source }: any) => {
  const record = useRecordContext<Statistics>();
  if (!record?.data) return null;
  const chartData: ChartData = JSON.parse(record.data);
  return (
    <span>
      총 판매량 :{' '}
      {
        chartData[chartData.length - 1].data[
          chartData[chartData.length - 1].data.length - 1
        ].count
      }
      개
      <br />총 상쇄량 :{' '}
      {
        chartData[chartData.length - 2].data[
          chartData[chartData.length - 1].data.length - 1
        ].count
      }
      kg
      <br />
      결제예샹금액 :{' '}
      {(
        chartData[chartData.length - 1].data[
          chartData[chartData.length - 1].data.length - 1
        ].count *
        8.6 *
        20
      ).toFixed(0)}
      원
    </span>
  );
};

const ListActions = (props: ListActionsProps) => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton />
      {/* <SyncButton {...props} /> */}
    </TopToolbar>
  );
};

export const StatisticsList = (props: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<TagPagination />}
    filters={itemFilters}
    sort={{ field: 'month', order: 'DESC' }}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit">
      <TextField source="brand_id" />
      {/* <ReferenceField source="brand_id" reference="brand">
        <TextField source="name" />
      </ReferenceField> */}
      <TextField source="month" />
      <ChartDataField source="data" />
      <DateField source="create_datetime" />
    </Datagrid>
  </List>
);
