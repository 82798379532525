// in src/users.js
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  NumberField,
  DateField,
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
  EditProps,
  Pagination,
  PaginationProps,
  BulkDeleteButton,
  ReferenceField,
  useGetOne,
  useRecordContext,
  Link,
} from 'react-admin';

const ItemFilter = (props: ListProps) => (
  <Filter {...props}>
    <TextInput label="브랜드" source="query" alwaysOn />
    <ReferenceInput label="Brand" source="brand_id" reference="brand" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const CategoryPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

type Category = {
  id: number;
  name: string;
  parent_category: Category;
};
const CategoryField = ({ source }: any) => {
  const record = useRecordContext<Category>();
  if (!record) return null;
  return (
    <>
      <Link to={`/category/${record?.id}`}>{record?.parent_category?.name}</Link>
    </>
  );
};

export const CategoryList = (props: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<CategoryPagination />}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <TextField source="name" />
      <CategoryField source="parent_category" />
      <NumberField source="order" />
      <DateField source="create_datetime" />
      <DateField source="update_datetime" />
    </Datagrid>
  </List>
);
