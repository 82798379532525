import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Create,
  CreateProps,
  SelectField,
  required,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const validateName = required();

export const SurveyCreate = (props: CreateProps) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput label="설문명" source="description" validate={validateName} />
      <TextInput source="type" defaultValue={null} />
      <TextInput label="설문 구분자(url)" source="slug" defaultValue={null} />
      <TextInput source="thumnail_url" defaultValue={null} fullWidth />
      <BooleanInput source="open" defaultValue={false} />
      <ArrayInput label="질문 항목 목록" source="questions">
        <SimpleFormIterator>
          <ReferenceInput
            source="id"
            reference="Question"
            defaultValue={null}
            perPage={50}
          >
            <AutocompleteInput
              label={'질문 제목'}
              optionText="name"
              defaultValue={null}
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
