import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  TextInput,
  required,
  Edit,
  ReferenceArrayField,
  Datagrid,
  TextField,
  ShowButton,
  DateField,
  Button,
  Toolbar,
  SaveButton,
  useRecordContext,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { httpClient } from '../../App';

const validateName = required();

const fetchManualPay = async () => {
  const options = {
    method: 'POST',
    headers: new Headers({}),
  };
  return await httpClient(`${process.env.REACT_APP_API_HOST}/offset/manualPay`, options);
};

const PostCreateToolbar = () => {
  const record = useRecordContext();
  if (record.is_payed) return <Button label="결제완료" disabled={true} />;
  return <Button label="결제하기" onClick={() => fetchManualPay()} />;
};

export const CompanyEdit = (props: EditProps) => {
  const transform = (data: any) => {
    const { brand_category, ...submitData } = data;
    return submitData;
  };
  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="id" disabled={true} />
        <TextInput label="회사명" source="name" validate={validateName} />
        <TextInput source="type" defaultValue={null} />
        <TextInput source="phone_number" defaultValue={null} />
        <TextInput source="address" fullWidth defaultValue={null} />
        <TextInput source="homepage" fullWidth defaultValue={null} />
        <TextInput source="offset_sum" label={'상쇄량 합계(t)'} disabled />
        <ReferenceArrayField source="offset_ids" reference="offset">
          <Datagrid>
            <DateField source="date_retired" />
            <TextField source="amount" />
            <TextField source="description" />
            <TextField source="is_payed" />
            <TextField source="certification_url" />
            <PostCreateToolbar />
          </Datagrid>
        </ReferenceArrayField>
      </SimpleForm>
    </Edit>
  );
};
