// in src/users.js
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  NumberField,
  DateField,
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
  EditProps,
  Pagination,
  PaginationProps,
  BulkDeleteButton,
  ReferenceField,
  BooleanField,
  UrlField,
} from 'react-admin';

const AdministratorPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

export const AdministratorList = (props: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<AdministratorPagination />}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <TextField source="username" />
      <TextField source="name" />
      <TextField source="phone_number" />
      <TextField source="role" />
      <TextField source="is_reset" />
      <TextField source="platform" />
      <TextField source="company_id" reference="company" />
      <ReferenceField source="company_id" reference="company">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="pay_start_datetime" />
      <DateField source="create_datetime" />
      <DateField source="update_datetime" />
    </Datagrid>
  </List>
);
