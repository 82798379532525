import * as React from 'react';
import {
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Edit,
  EditProps,
  SelectField,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  RadioButtonGroupInput,
  required,
  BooleanInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { AnswerStyle, QuestionType } from '../../const';

const validateName = required();

export const QuestionEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput
        label="질문 페이지 제목"
        source="name"
        validate={validateName}
        fullWidth
      />
      <BooleanInput label="제목을 보여주나요?" source="showName" defaultValue={false} />
      <RichTextInput source="description" validate={validateName} fullWidth />
      {/* <TextInput source="type" defaultValue={null} fullWidth /> */}
      <TextInput source="thumnail_url" defaultValue={null} fullWidth />
      <ArrayInput label="질문 항목 목록" source="answers" fullWidth>
        <SimpleFormIterator fullWidth inline>
          <RadioButtonGroupInput source="type" fullWidth choices={QuestionType} />
          <TextInput source="title" fullWidth />
          {/* <RichTextInput source="title" fullWidth /> */}
          <NumberInput source="rangeMin" style={{ display: 'inline-block' }} />
          <NumberInput source="rangeMax" style={{ display: 'inline-block' }} />
          <NumberInput source="rangeStep" style={{ display: 'inline-block' }} />
          <BooleanInput label="값 타입" source="showWon" defaultValue={false} />
          <RadioButtonGroupInput source="style" fullWidth choices={AnswerStyle} />
          <BooleanInput
            label="다른 질문으로 분기되는 질문인가요?"
            source="isBranchAnswer"
            defaultValue={false}
          />
          <BooleanInput
            label="다른 질문값과 연산되는 질문인가요?"
            source="isCombineAnswer"
            defaultValue={false}
          />
          <div>함께 계산할 질문의 index(0부터)를 입력해주세요</div>
          <NumberInput label="질문 index(0부터)" source="comebinedQuestionIndex" />

          <ReferenceInput
            label="할당할 계수"
            source="assign"
            reference="sector"
            defaultValue={null}
            perPage={20}
          >
            <AutocompleteInput optionText="name" defaultValue={null} fullWidth />
          </ReferenceInput>
          <BooleanInput source="required" defaultValue={true} />
          <ArrayInput label="옵션 목록" source="options">
            <SimpleFormIterator fullWidth inline>
              <TextInput label="실제값" source="value" />
              <TextInput source="type(null or disabled)" />
              <NumberInput source="co2" />
              <ReferenceInput
                label="다음 질문"
                source="next_question_id"
                reference="Question"
                defaultValue={null}
                perPage={50}
              >
                <AutocompleteInput
                  label={'다음 질문 이름'}
                  optionText="name"
                  defaultValue={null}
                  fullWidth
                />
              </ReferenceInput>
              {/* <NumberInput source="next_question_id" /> */}
              <TextInput label="텍스트" source="option" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ReferenceInput
        label="이전 질문(분기가 없는 경우)"
        source="previous_question_id"
        reference="Question"
        defaultValue={null}
        perPage={50}
      >
        <AutocompleteInput
          label={'이전 질문(분기가 없는 경우)'}
          optionText="name"
          defaultValue={null}
          fullWidth
        />
      </ReferenceInput>
      <ReferenceInput
        label="다음 질문(분기가 없는 경우)"
        source="next_question_id"
        reference="Question"
        defaultValue={null}
        perPage={50}
      >
        <AutocompleteInput
          label={'다음 질문(분기가 없는 경우) - 마지막 질문일 경우 null'}
          optionText="name"
          defaultValue={null}
          fullWidth
        />
      </ReferenceInput>
      {/* <TextInput label="할당할 카테고리" source="assign" /> */}
    </SimpleForm>
  </Edit>
);
