import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ListProps,
  NumberField,
  DateField,
} from 'react-admin';

export const CompanyList = (props: ListProps) => (
  <List {...props} perPage={20}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="type" />
      <TextField source="address" />
      <TextField source="industry_name" />
      <TextField source="company_number" />
      <TextField source="phone_number" />
      <TextField source="director_name" />
      <DateField source="create_datetime" />
      <DateField source="update_datetime" />
    </Datagrid>
  </List>
);
