import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Create,
  CreateProps,
  SelectField,
  AutocompleteInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

export const CategoryCreate = (props: CreateProps) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput
        source="parent_category_id"
        reference="category"
        defaultValue={null}
        perPage={50}
      >
        <AutocompleteInput optionText="name" defaultValue={null} />
      </ReferenceInput>
      <NumberInput source="order" defaultValue={0} />
      <TextInput source="thumnail_url" defaultValue={null} />
    </SimpleForm>
  </Create>
);
