import * as React from "react";
import { EditProps, SimpleForm, DateInput, NumberInput, ReferenceInput, SelectInput, TextInput, Create } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

export const UserCreate = (props: EditProps) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="username" />
            <TextInput source="name" />
            <TextInput source="password" />
            <TextInput source="phone_number" />
            <TextInput source="role" />
            <DateInput source="create_datetime" disabled />
            <DateInput source="update_datetime" disabled />
        </SimpleForm>
    </Create>
);