import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useState } from 'react';
import { httpClient } from '../../App';
import { Button } from '@mui/material';

const fetchUpload = (selectedFile: File) => {
  const formData = new FormData();
  formData.append('file', selectedFile);

  const options = {
    method: 'POST',
    headers: new Headers({
      //   'Content-Type': 'multipart/form-data',
    }),
    body: formData,
  };
  httpClient(`${process.env.REACT_APP_API_HOST}/item/excel_upload`, options);
};

export const ImportButton = ({ mutationMode = 'undoable', ...props }: any) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  console.log(mutationMode, props);
  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmission = (e: any) => {
    console.log(e);
    console.log(selectedFile);
    if (selectedFile) {
      fetchUpload(selectedFile);
    }
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <>
      <form onSubmit={handleSubmission}>
        <input type="file" name="file" onChange={changeHandler} />
        <Button type="submit" onClick={handleSubmission} variant="outlined">
          <PlaylistAddIcon />
          import excel
        </Button>
      </form>
    </>
  );
};
