import { Card, CardContent, CardHeader } from '@mui/material';
import * as React from 'react';

export default () => (
  <Card>
    <CardHeader title="내일의 쓰임 백오피스(관리자 도구)" />
    <CardContent>스테핑 어드민에 오신 것을 환영합니다.</CardContent>
    <CardContent>스테핑은 기업들의 탄소중립을 지원합니다.</CardContent>
    <CardContent>
      <a href="https://stepping.co.kr" target="_blank">
        스테핑 홈페이지{' '}
      </a>
      &nbsp;|&nbsp;
      <a
        href="https://www.notion.so/V3-c064e790639c4c04801d79dcc7c2d280"
        target={'_blank'}
      >
        스테핑 V3 기획안 보러가기
      </a>
    </CardContent>
    <CardContent>
      <a href="#/statistics">브랜드별 상쇄량 확인</a>
    </CardContent>
  </Card>
);
