import * as React from 'react';
import {
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Edit,
  EditProps,
  SelectField,
  AutocompleteInput,
} from 'react-admin';

export const CategoryEdit = (props: EditProps) => {
  const transform = (data: any) => {
    const { child_categories, level, ...submitData } = data;
    return submitData;
  };
  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="id" disabled />
        <TextInput source="name" />
        <ReferenceInput
          source="parent_category_id"
          reference="category"
          defaultValue={null}
          perPage={50}
        >
          <AutocompleteInput optionText="name" defaultValue={null} />
        </ReferenceInput>
        <NumberInput source="order" defaultValue={0} />
        <TextInput source="thumnail_url" defaultValue={null} />
        <DateInput source="create_datetime" defaultValue={null} disabled />
        <DateInput source="update_datetime" defaultValue={null} disabled />
      </SimpleForm>
    </Edit>
  );
};
