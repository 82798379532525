interface LoginProps {
  username: string;
  password: string;
}

interface responseProps {
  status: number;
}

const authProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }: LoginProps) => {
    // accept all username/password combinations
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    };
    try {
      const result = await fetch(`${process.env.REACT_APP_API_HOST}/auth/login`, options)
        .then((response) => {
          if (response.status !== 201 && response.status !== 200) {
            throw new Error(`${response.status}`);
          }
          return response;
        })
        .then((response) => response.json())
        .then((responseJSON) => {
          console.log(responseJSON);
          if (responseJSON.access_token) {
            localStorage.setItem('access_token', responseJSON.access_token);
          } else {
            throw new Error(`${responseJSON}`);
          }
          return Promise.resolve();
        });
    } catch (e) {
      return Promise.reject();
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('access_token');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }: responseProps) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('access_token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('access_token') ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
