import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Create,
  CreateProps,
  SelectField,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Grid } from '@mui/material';

const itemDefaultValue = () => ({
  name: null,
  brand: null,
  company_id: null,
  total_stock: null,
});

export const ItemCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm sx={{ marginBottom: '100px' }}>
      <TextInput source="name" sx={{ width: '100%' }} />
      <ReferenceInput
        source="brand_id"
        reference="brand"
        defaultValue={null}
        perPage={50}
      >
        <AutocompleteInput sx={{ minWidth: '200px !important' }} />
      </ReferenceInput>
      <ReferenceArrayInput
        label="Categories"
        reference="category"
        source="category_id_list"
        defaultValue={null}
        perPage={50}
      >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="태그" reference="tag" source="tag_id_list" perPage={50}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="솔루션"
        reference="solution"
        source="solution_id_list"
        perPage={50}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput source="thumnail_url" defaultValue={null} sx={{ width: '100%' }} />
      <RichTextInput source="description" fullWidth />
      <SelectInput
        source="item_status"
        choices={[
          { id: 1, name: '준비중' },
          { id: 2, name: '판매중' },
          { id: 3, name: '판매중지' },
        ]}
        defaultValue={1}
        emptyValue={1}
        required
      />
      <BooleanInput source="open" defaultValue={null} />

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <NumberInput source="list_price" defaultValue={null} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={4}>
          <NumberInput source="sell_price" defaultValue={null} sx={{ width: '100%' }} />
        </Grid>
        <Grid item xs={4}>
          <NumberInput source="delivery_fee" defaultValue={null} sx={{ width: '100%' }} />
        </Grid>
      </Grid>
      <NumberInput source="total_stock" defaultValue={null} />
      <TextInput source="option_type" defaultValue={null} />
      <TextInput source="options_temp" />
    </SimpleForm>
  </Create>
);
