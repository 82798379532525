import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  SelectField,
  required,
  Edit,
} from 'react-admin';

const validateName = required();

export const TagEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="태그명" source="name" validate={validateName} />
      <TextInput source="type" defaultValue={null} />
    </SimpleForm>
  </Edit>
);
