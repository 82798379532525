import * as React from 'react';
import { EditProps, SimpleForm, TextInput, required, Edit } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { JsonInput } from 'react-admin-json-view';

const validateName = required();

export const BrandEdit = (props: EditProps) => {
  const transform = (data: any) => {
    const { brand_category, ...submitData } = data;
    return submitData;
  };
  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput label="브랜드명" source="name" validate={validateName} />
        <TextInput source="type" defaultValue={null} />
        <TextInput source="brand_category" defaultValue={null} />
        <TextInput source="company_name" defaultValue={null} />
        <TextInput source="summary" defaultValue={null} />
        <TextInput source="store_id" disabled={true} />
        <TextInput source="logo_url" fullWidth defaultValue={null} />
        <JsonInput source="plugin_setting" validate={[required()]} jsonString={false} />
        <JsonInput source="plan" validate={[required()]} jsonString={false} />
        <RichTextInput source="description" fullWidth />
      </SimpleForm>
    </Edit>
  );
};
