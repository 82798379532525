import * as React from 'react';
import {
  EditProps,
  Edit,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectArrayInput,
  BooleanInput,
  useRecordContext,
  useGetMany,
  Link,
  AutocompleteInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const customAction = (props: any) => {
  console.log(props);
};
const choices = [
  { id: 123, first_name: 'Leo', last_name: 'Tolstoi', avatar: '/pengouin' },
  { id: 456, first_name: 'Jane', last_name: 'Austen', avatar: '/panda' },
];
const OptionRenderer = (choice: any) => (
  <span>
    {choice.first_name}
    {choice.last_name}
  </span>
);
const inputText = (choice: any) => `${choice.first_name} ${choice.last_name}`;

type Item = {
  id: number;
  itemLinks: any[];
};
type ItemLink = {
  id: number;
  price: number;
  url: string;
};

const ItemLinkList = () => {
  const record = useRecordContext<Item>();
  const itemLinkIds = record?.itemLinks
    ? record?.itemLinks?.map((itemLink) => itemLink.id)
    : [];
  const { isLoading, data: itemLinks } = useGetMany<ItemLink>(
    'item-link',
    { ids: itemLinkIds },
    { enabled: !!record && itemLinkIds.length > 0 },
  );
  const itemLinksById = itemLinks
    ? itemLinks?.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {} as any)
    : {};
  if (isLoading || !record || !itemLinks) return null;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>NAME</TableCell>
          <TableCell>PRICE</TableCell>
          <TableCell>URL</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record?.itemLinks?.map((itemLink: any) => (
          <TableRow key={itemLink.product_id}>
            <TableCell>
              <Link to={`/item-link/${itemLink.id}`}>
                {itemLinksById[itemLink.id].id}
              </Link>
            </TableCell>
            <TableCell>{itemLinksById[itemLink.id].price}</TableCell>
            <TableCell>
              <a href={itemLinksById[itemLink.id].url} target="_blank">
                {itemLinksById[itemLink.id].url}
              </a>
            </TableCell>
          </TableRow>
        ))}
        <TableRow key={`new-item`}>
          <TableCell colSpan={3}>
            <Button
              component={Link}
              to={`/item-link/create?item_id=${record?.id}`}
              variant="contained"
              color="primary"
            >
              링크 추가하기
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const ItemEdit = (props: EditProps) => {
  const transform = (data: any) => {
    const {
      category_id,
      create_datetime,
      update_datetime,
      brand,
      categories,
      category_list,
      tags,
      itemLinks,
      itemLink_id_list,
      solutions,
      ...submitData
    } = data;
    if (submitData.brand_id) {
      submitData.brand_id = parseInt(submitData.brand_id);
    }
    return submitData;
  };

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="name" defaultValue={null} sx={{ width: '100%' }} />
        {/* <TextInput source="type" defaultValue={null} /> */}
        {/* <TextInput source="category_imweb" defaultValue={null} /> */}
        <ReferenceInput
          source="brand_id"
          reference="brand"
          defaultValue={null}
          perPage={50}
        >
          <AutocompleteInput />
        </ReferenceInput>
        <ReferenceArrayInput
          label="태그"
          reference="tag"
          source="tag_id_list"
          perPage={50}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="솔루션"
          reference="solution"
          source="solution_id_list"
          perPage={50}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          label="Categories"
          reference="category"
          source="category_id_list"
          defaultValue={null}
          perPage={50}
        >
          <AutocompleteArrayInput />
        </ReferenceArrayInput>

        {/* <NumberInput source="sell_status" defaultValue={null} /> */}
        <SelectInput
          source="item_status"
          choices={[
            { id: 1, name: '준비중' },
            { id: 2, name: '판매중' },
            { id: 3, name: '판매중지' },
          ]}
          defaultValue="1"
          emptyValue="1"
          required
        />
        <TextInput source="thumnail_url" fullWidth defaultValue={null} />

        <RichTextInput source="description" fullWidth />
        <BooleanInput source="open" defaultValue={null} />

        {/* <TextInput source="option_type" defaultValue={null} />
        <TextInput source="options_temp" defaultValue={null} />
        <NumberInput source="total_stock" defaultValue={null} /> */}

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <NumberInput source="list_price" defaultValue={null} sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={4}>
            <NumberInput source="sell_price" defaultValue={null} sx={{ width: '100%' }} />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              source="delivery_fee"
              defaultValue={null}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <DateInput source="create_datetime" defaultValue={null} disabled />
        <DateInput source="update_datetime" defaultValue={null} />
        <Typography variant="h6" gutterBottom>
          상품링크
        </Typography>
        <ItemLinkList />
        <br />
        <br />
        <br />
        <br />
      </SimpleForm>
    </Edit>
  );
};
