import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Create,
  CreateProps,
  SelectField,
  required,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const validateName = required();

export const SolutionCreate = (props: CreateProps) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput label="솔루션명" source="name" validate={validateName} />
      <TextInput source="type" defaultValue={null} />
      <TextInput source="thumnail_url" defaultValue={null} />
    </SimpleForm>
  </Create>
);
