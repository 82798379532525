// in src/users.js
import * as React from 'react';
import { Fragment } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ListProps,
  NumberField,
  DateField,
  ReferenceField,
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
  EditProps,
  ImageField,
  Pagination,
  PaginationProps,
  BulkDeleteButton,
  Link,
  useRecordContext,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TopToolbar,
  CreateButton,
  ListActionsProps,
} from 'react-admin';
import { ImportButton } from './ImportButton';

const ItemPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const ItemBulkActionButtons = (props: any) => (
  <>
    {/* <ResetViewsButton label="Reset Views" {...props} /> */}
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} />
  </>
);

const ListActions = (props: ListActionsProps) => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton />
      <ImportButton {...props} />
    </TopToolbar>
  );
};

type Item = {
  id: number;
  name: string;
  categories: any;
  brand: any;
};
const stopPropagation = (e: any) => e.stopPropagation();
const BrandField = ({ source }: any) => {
  const record = useRecordContext<Item>();
  if (!record?.brand) return null;
  const brand = record.brand;
  return (
    <span>
      <Link to={`/brand/${brand.id}`} onClick={stopPropagation}>
        {brand.name}
      </Link>
    </span>
  );
};
const CategoryField = ({ source }: any) => {
  const record = useRecordContext<Item>();
  if (!record) return null;
  return (
    <>
      {record.categories?.map((category: any) => (
        <span>
          <Link to={`/category/${category?.id}`} onClick={stopPropagation}>
            {category.name}
          </Link>
        </span>
      ))}
    </>
  );
};

const itemFilters = [
  <TextInput label="상품명 검색" source="q" alwaysOn />,
  <ReferenceArrayInput
    label="Brand"
    source="brands"
    reference="brand"
    allowEmpty
    alwaysOn
  >
    <AutocompleteArrayInput optionText="name" debounce={1000} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput
    label="Categories"
    reference="category"
    source="categories"
    defaultValue={null}
    perPage={50}
  >
    <AutocompleteArrayInput />
  </ReferenceArrayInput>,
];

export const ItemList = (props: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<ItemPagination />}
    sort={{ field: 'id', order: 'DESC' }}
    filters={itemFilters}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <TextField source="name" sx={{ width: '100%' }} />
      <ImageField
        source="thumnail_url"
        sx={{ '& .RaImageField-image': { height: '100px' } }}
      />
      <BrandField source="brand" />
      <CategoryField source="categories" />
      {/* <TextField source="type" /> */}
      {/* <NumberField source="company_id" /> */}
      {/* <NumberField source="sell_status" /> */}
      <NumberField source="item_status" />
      <DateField source="create_datetime" />
      <DateField source="update_datetime" />
      {/* <TextField source="categories.name" /> */}
      {/* <TextField source="company.name" /> */}
    </Datagrid>
  </List>
);
