// in src/users.js
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  NumberField,
  DateField,
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
  EditProps,
  Pagination,
  PaginationProps,
  BulkDeleteButton,
} from 'react-admin';

const ItemFilter = (props: ListProps) => (
  <Filter {...props}>
    <TextInput label="브랜드" source="query" alwaysOn />
    <ReferenceInput label="Brand" source="brand_id" reference="brand" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ItemLinkPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

export const ItemLinkList = (props: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<ItemLinkPagination />}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="price" />
      <TextField source="url" />
      <DateField source="create_datetime" />
      <DateField source="update_datetime" />
    </Datagrid>
  </List>
);
