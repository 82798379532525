// in src/users.js
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  NumberField,
  DateField,
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
  EditProps,
  Pagination,
  PaginationProps,
  BulkDeleteButton,
  ReferenceField,
  BooleanField,
  UrlField,
} from 'react-admin';

const OffsetPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

export const OffsetList = (props: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<OffsetPagination />}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <DateField source="create_datetime" />
      <ReferenceField source="company_id" reference="company">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="is_payed" />
      <NumberField source="amount" />
      <TextField source="payment_type" />
      <TextField source="description" />
      <UrlField source="certification_url" />
    </Datagrid>
  </List>
);
