import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Create,
  CreateProps,
  SelectField,
  required,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const validateName = required();

export const NoticeCreate = (props: CreateProps) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput label="제목" source="title" validate={validateName} />
      <RichTextInput source="description" fullWidth />
      <TextInput source="type" defaultValue={null} />
    </SimpleForm>
  </Create>
);
