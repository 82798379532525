import * as React from "react";
import { List, Datagrid, TextField, EmailField, ListProps, NumberField, DateField, ReferenceField, TextInput, Filter, ReferenceInput, SelectInput, EditProps, ImageField } from 'react-admin';

export const UserList = (props: ListProps) => (
    <List {...props}  >
        <Datagrid rowClick="edit">
            <NumberField source="id" />
            <TextField source="username" />
            <TextField source="name" />
            <TextField source="phone_number" />
            <TextField source="role" />
            <DateField source="create_datetime" />
            <DateField source="update_datetime" />
        </Datagrid>
    </List>
);