import * as React from 'react';
import {
  SimpleForm,
  DateInput,
  NumberInput,
  TextInput,
  Create,
  CreateProps,
  required,
  BooleanInput,
} from 'react-admin';

const validateName = required();

export const SectorCreate = (props: CreateProps) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <NumberInput label="코드" source="code" fullWidth />
      <TextInput label="계수 이름" source="name" fullWidth />
      <NumberInput label="계수" source="emission" fullWidth />
    </SimpleForm>
  </Create>
);
