import { useEffect, useState } from 'react';
import {
  EditProps,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Create,
  CreateProps,
  SelectField,
  AutocompleteInput,
  useGetOne,
} from 'react-admin';
import { useQuery } from '../../hooks';

type Item = {
  id: number;
  name: string;
  itemLinks: any[];
};

export const ItemLinkCreate = (props: CreateProps) => {
  const [baseItemId, setBaseItemId] = useState<number | null>(null);
  const [baseItem, setBaseItem] = useState<Item>();
  const query = useQuery();
  useEffect(() => {
    if (query.get('item_id')) {
      setBaseItemId(parseInt(query.get('item_id') ?? ''));
    }
  }, [query]);

  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput source="name" />
        <NumberInput source="price" />
        <TextInput source="url" isRequired={true} sx={{ width: '100%' }} />
        <ReferenceInput source="item_id" reference="item" perPage={50} isRequired={true}>
          <AutocompleteInput
            optionText="name"
            defaultValue={baseItemId}
            sx={{ width: '100%' }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
