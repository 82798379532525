import React from 'react';
import './App.css';
import jsonServerProvider from 'ra-data-json-server';
import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  fetchUtils,
  ShowGuesser,
} from 'react-admin';
import { ItemCreate } from './model/item/ItemCreate';
import DashBoard from './model/DashBoard';
import { BrandList } from './model/BrandList';
import { UserList } from './model/user/UserList';
import authProvider from './auth/authProvider';
import { UserCreate } from './model/user/UserCreate';
import simpleRestProvider from 'ra-data-simple-rest';
import { ItemList } from './model/item/ItemList';
import { ItemEdit } from './model/item/ItemEdit';
import { BrandCreate } from './model/brand/BrandCreate';
import { TagList } from './model/tag/TagList';
import { TagCreate } from './model/tag/TagCreate';
import { TagEdit } from './model/tag/TagEdit';
import { BrandEdit } from './model/brand/BrandEdit';
import { ItemLinkCreate } from './model/itemLink/ItemLinkCreate';
import { ItemLinkList } from './model/itemLink/ItemLinkList';
import { ItemLinkEdit } from './model/itemLink/ItemLinkEdit';
import { CategoryList } from './model/category/CategoryList';
import { CategoryEdit } from './model/category/CategoryEdit';
import { CategoryCreate } from './model/category/CategoryCreate';
import { SolutionList } from './model/solution/SolutionList';
import { SolutionEdit } from './model/solution/SolutionEdit';
import { SolutionCreate } from './model/solution/SolutionCreate';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CategoryIcon from '@mui/icons-material/Category';
import LinkIcon from '@mui/icons-material/Link';
import TagIcon from '@mui/icons-material/Tag';
import HandshakeIcon from '@mui/icons-material/Handshake';
import BiotechIcon from '@mui/icons-material/Biotech';
import PollIcon from '@mui/icons-material/Poll';
import QuizIcon from '@mui/icons-material/Quiz';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SchoolIcon from '@mui/icons-material/School';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FactoryIcon from '@mui/icons-material/Factory';
import TimelineIcon from '@mui/icons-material/Timeline';
import { SurveyList } from './model/survey/SurveyList';
import { SurveyCreate } from './model/survey/SurveyCreate';
import { QuestionCreate } from './model/question/QuestionCreate';
import { QuestionList } from './model/question/QuestionList';
import { QuestionEdit } from './model/question/QuestionEdit';
import { SurveyEdit } from './model/survey/SurveyEdit';
import { KnowledgeList } from './model/knowledge/KnowledgeList';
import { KnowledgeCreate } from './model/knowledge/KnowledgeCreate';
import { KnowledgeEdit } from './model/knowledge/KnowledgeEdit';
import { CompanyList } from './model/company/CompanyList';
import { CompanyEdit } from './model/company/CompanyEdit';
import { CompanyCreate } from './model/company/CompanyCreate';
import { StatisticsList } from './model/statistics/StatisticList';
import { SectorList } from './model/sector/SectorList';
import { NoticeList } from './model/notice/NoticeList';
import { NoticeEdit } from './model/notice/NoticeEdit';
import { NoticeCreate } from './model/notice/NoticeCreate';
import { SectorEdit } from './model/sector/SectorEdit';
import { SectorCreate } from './model/sector/SectorCreate';
import { SurveyResponseList } from './model/surveyResponse/SurveyResponseList';
import { SurveyResponseEdit } from './model/surveyResponse/SurveyResponseEdit';
import { OffsetList } from './model/offset/OffsetList';
import { AdministratorList } from './model/administrator/AdministratorList';

type Option = {
  headers?: Headers;
};

export const httpClient = (url: string, options: Option & any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('access_token');
  options.headers.set('Authorization', `Bearer ${token}`);
  try {
    return fetchUtils.fetchJson(url, options);
  } catch (e) {
    console.log(e);
  }
};
// const dataProvider = simpleRestProvider('http://localhost:3000', httpClient, 'X-Total-Count');

const dataProvider = jsonServerProvider(`${process.env.REACT_APP_API_HOST}`, httpClient);

function App() {
  return (
    <Admin
      title="내일의 쓰임 어드민"
      dashboard={DashBoard}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      {/* <Admin title="내일의 쓰임 어드민" dashboard={DashBoard} dataProvider={dataProvider}> */}
      <Resource
        name="administrator"
        options={{ label: '관리자' }}
        list={AdministratorList}
        icon={AdminPanelSettingsIcon}
      />
      <Resource
        name="user"
        options={{ label: '유저' }}
        list={UserList}
        edit={EditGuesser}
        create={UserCreate}
        icon={PersonIcon}
      />
      <Resource
        name="company"
        options={{ label: '기업' }}
        list={CompanyList}
        edit={CompanyEdit}
        create={CompanyCreate}
      />
      <Resource
        name="category"
        options={{ label: '카테고리' }}
        list={CategoryList}
        edit={CategoryEdit}
        create={CategoryCreate}
        icon={CategoryIcon}
      />
      <Resource
        name="solution"
        options={{ label: '솔루션' }}
        list={SolutionList}
        edit={SolutionEdit}
        create={SolutionCreate}
        icon={BiotechIcon}
      />
      <Resource
        name="brand"
        options={{ label: '브랜드' }}
        list={BrandList}
        edit={BrandEdit}
        create={BrandCreate}
        icon={HandshakeIcon}
      />
      <Resource
        name="item"
        options={{ label: '상품' }}
        list={ItemList}
        edit={ItemEdit}
        create={ItemCreate}
        icon={ShoppingCartIcon}
      />
      <Resource
        name="item-link"
        options={{ label: '상품링크' }}
        list={ItemLinkList}
        edit={ItemLinkEdit}
        create={ItemLinkCreate}
        icon={LinkIcon}
      />
      <Resource
        name="tag"
        options={{ label: '태그' }}
        list={TagList}
        edit={TagEdit}
        create={TagCreate}
        icon={TagIcon}
      />
      <Resource
        name="sector"
        options={{ label: '산업군 계수' }}
        list={SectorList}
        edit={SectorEdit}
        create={SectorCreate}
        icon={FactoryIcon}
      />
      <Resource
        name="survey"
        options={{ label: '계산기 설문' }}
        list={SurveyList}
        edit={SurveyEdit}
        create={SurveyCreate}
        icon={PollIcon}
      />
      <Resource
        name="question"
        options={{ label: '계산기 질문' }}
        list={QuestionList}
        edit={QuestionEdit}
        create={QuestionCreate}
        icon={QuizIcon}
      />
      <Resource
        name="surveyResponse"
        options={{ label: '응답' }}
        list={SurveyResponseList}
        edit={SurveyResponseEdit}
        icon={RateReviewIcon}
      />
      <Resource
        name="knowledge"
        options={{ label: '지식' }}
        list={KnowledgeList}
        edit={KnowledgeEdit}
        create={KnowledgeCreate}
        icon={SchoolIcon}
      />
      <Resource
        name="statistics"
        options={{ label: '상쇄량 통계' }}
        list={StatisticsList}
        edit={KnowledgeEdit}
        create={KnowledgeCreate}
        icon={SchoolIcon}
      />
      <Resource
        name="offset"
        options={{ label: '월별 상쇄량집계' }}
        list={OffsetList}
        icon={TimelineIcon}
      />
      <Resource
        name="notice"
        options={{ label: '공지' }}
        list={NoticeList}
        edit={NoticeEdit}
        create={NoticeCreate}
        icon={PostAddIcon}
      />
    </Admin>
  );
}

export default App;
