// in src/users.js
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  NumberField,
  DateField,
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
  EditProps,
  Pagination,
  PaginationProps,
  BulkDeleteButton,
} from 'react-admin';

const SurveyResponsePagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

export const SurveyResponseList = (props: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={<SurveyResponsePagination />}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <TextField source="type" />
      <TextField source="user" />
      <TextField source="description" />
      <DateField source="create_datetime" />
    </Datagrid>
  </List>
);
