import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  NumberInput,
  TextInput,
  required,
  Edit,
  DateInput,
} from 'react-admin';

const validateName = required();

export const SolutionEdit = (props: EditProps) => (
  <Edit {...props}>
    <Edit redirect="list">
      <TextInput label="솔루션명" source="name" validate={validateName} />
      <TextInput source="type" defaultValue={null} />
      <TextInput source="thumnail_url" defaultValue={null} />
      <DateInput source="create_datetime" disabled />
      <DateInput source="update_datetime" disabled />
    </Edit>
  </Edit>
);
