import * as React from 'react';
import {
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Edit,
  EditProps,
  SelectField,
  AutocompleteInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

export const ItemLinkEdit = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="price" />
      <TextInput source="url" sx={{ width: '100%' }} />
      <ReferenceInput source="item_id" reference="item" defaultValue={null} perPage={50}>
        <AutocompleteInput optionText="name" defaultValue={null} sx={{ width: '100%' }} />
      </ReferenceInput>
      <DateInput source="create_datetime" defaultValue={null} disabled />
      <DateInput source="update_datetime" defaultValue={null} disabled />
    </SimpleForm>
  </Edit>
);
