import * as React from "react";
import { List, Datagrid, TextField, EmailField, ListProps, NumberField, DateField, ReferenceField } from 'react-admin';

export const BrandList = (props: ListProps) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="type" />
            <TextField source="brand_category" />
            <TextField source="company_name" />
            <TextField source="description" />
            <DateField source="create_datetime" />
            <DateField source="update_datetime" />
        </Datagrid>
    </List>
);