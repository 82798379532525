import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Create,
  CreateProps,
  SelectField,
  AutocompleteInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const required = () => (value: string) =>
  value?.length < 100 ? undefined : 'author should be under length 100';

export const KnowledgeCreate = (props: CreateProps) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="name" fullWidth />
      <TextInput source="link" fullWidth />
      <RichTextInput source="description" fullWidth />
      <TextInput source="thumnail_url" defaultValue={null} fullWidth />
      <TextInput source="author" validate={required()} />
    </SimpleForm>
  </Create>
);
