import * as React from 'react';
import {
  EditProps,
  SimpleForm,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Create,
  CreateProps,
  SelectField,
  required,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

const validateName = required();

export const BrandCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="브랜드명" source="name" validate={validateName} />
      <TextInput source="type" defaultValue={null} />
      <TextInput source="brand_category" defaultValue={null} />
      <TextInput source="company_name" defaultValue={null} />
      <TextInput source="logo_url" fullWidth defaultValue={null} />
      <TextInput source="summary" defaultValue={null} />
      <RichTextInput source="description" fullWidth />
    </SimpleForm>
  </Create>
);
